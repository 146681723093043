$(function () {

    var $window     = $(window);

    var $svgLogo = $('.svg');
    if($svgLogo.length > 0) {
        function useSvg() {
            return document.implementation.hasFeature("http://www.w3.org/TR/SVG11/feature#Image", "1.1");
        }

        if ( ! useSvg() ) {
            $svgLogo.prop('src', $svgLogo.data('fallback'));
        }
    }

    $('.filters_button').on('click', function() {
        $(this).toggleClass('active');
        $('.pageCategory .extended_filters_row').slideToggle(300);
    });

    var $interestForm = $('#interestForm'),
    $overlay = $('#overlay'),
    $popupWindows = $('a.popup');

    $popupWindows.on('click', function(e) {
        e.preventDefault();
        var $this = $(this);
        $target = $this.attr("href");
        $overlay.add($target).addClass('active');
    });

    $overlay.add($('.closeBtn', $interestForm)).on('click', function() {
        $overlay.add('.popupDialog.active').removeClass('active');
    });



    $interestForm.on('submit', function(e) {
            var $result = $('.result', $interestForm);
            e.preventDefault();
            var $this = $(this);

            postData = $this.serialize() + '&' + $('#cartString').serialize();

            $.ajax({
                url: BASE_URL +"interestForm.php",
                type: 'POST',
                data: postData,
                dataType: 'html',
                success: function(message) {
                    $('input[type=text]', $interestForm).val('');
                    this.showMessage(message);
                },
                error: function(xhr, status, error) {
                    this.showMessage(xhr.responseText);
                },
                showMessage: function(message) {
                    if($result.is(':visible')) {
                        $result.fadeOut(500, function() {
                            $result.html(message).fadeIn(500);
                        });
                    } else {
                        $result.html(message).fadeIn(500);
                    }
                }
            });
        });

    function initBlogSlide() {
        if ($('.swiper-container.slide').length > 0) {
            var slide = new Swiper ('.swiper-container.slide', {
                autoplay: 4000,
                loop: true,
                speed: 1000,
                spaceBetween: 0,
                direction: 'vertical',
                slidesPerView: 2,
                slidesPerGroup: 2,
                breakpoints: {
                    740: {
                      slidesPerView: 1,
                      slidesPerGroup: 1,
                      spaceBetween: 0,
                    }
                }
            });
        }
    }
    // initBlogSlide();

    $slideShow = $('#cycle');

    function checkSlideShowImagesHeight() {
        var $slideShowImagesParent = $('.slide_wrapper');
        var $slideShowImages = $('#cycle .cycle-slide-active img');
        
        console.log('slideShowImages: '+$slideShowImages.height(), $slideShowImages);
        console.log('slideShowImagesParent: '+$slideShowImagesParent.height());

        if ($slideShowImages.height() <= $slideShowImagesParent.height()) {
            $slideShow.addClass('fullHeight')
        } else {
            $slideShow.removeClass('fullHeight')
        }
    }
    // checkSlideShowImagesHeight();

    var resizeTimer;
    $(window).on('resize', function(e) {
      clearTimeout(resizeTimer);
      resizeTimer = setTimeout(function() {
        initBlogSlide();
        // checkSlideShowImagesHeight();
      }, 250);
    }).trigger('resize');

    new Swiper ('.references .swiper-container', {
        loop: true,
        speed: 1000,
        spaceBetween: 20,
        prevButton: $('.references .swiper-button-prev'),
        nextButton: $('.references .swiper-button-next'),
        slidesPerView: 3,
        slidesPerGroup: 1,
        breakpoints: {
            480: {
              slidesPerView: 1,
            },
            740: {
              slidesPerView: 2
            }
        }
    });

    var $menu = $('ul.categories');
    var $wrapWidth = $menu.closest('.wrap').width();
    var $animationLength = ($wrapWidth - $menu.width()) / 2;
    function animateMenu($fixed) {
        if($fixed) {
            $menu.animate({
                marginLeft: $animationLength +'px'
            }, 400);
        } else {
            $menu.animate({
                marginLeft: '0px'
            }, 400);
        }
    }

    if($window.width() > 1024) {
        var $el = $('nav.mainMenu');
        var $scrollTop = $el.offset().top;
        var $isFixed = false;
        $(document).on('scroll', function(e) {
            var $this = $(this);
            if($isFixed === false) {
                if($this.scrollTop() >= $scrollTop) {
                    $el.addClass('fixed');
                    $isFixed = true;
                    animateMenu($isFixed);
                }
            } else {
                if($this.scrollTop() < $scrollTop) {
                    $el.removeClass('fixed');
                    $isFixed = false;
                    animateMenu($isFixed);
                }
            }
        }).trigger('scroll');
    }
    
    var $treeMenu   = $('.treeMenu');
    if(RESPONSIVE) {
        var $slideBars      = new $.slidebars(); // Create new instance of Slidebars
        var $slidebarLeft   = $('.slidebarLeft');
        var $slidebarRight  = $('.slidebarRight');
        var $sbLeft         = $('.sb-left');
        var $sbRight        = $('.sb-right');

        //Open left slidebar
        if($sbLeft.length > 0) {
            $slidebarLeft.on('click', function(e) {
                e.preventDefault();
                $slideBars.slidebars.toggle('left');
            });
        }

        //Open right slidebar
        if($sbRight.length > 0) {
            $slidebarRight.on('click', function(e) {
                e.preventDefault();
                $slideBars.slidebars.toggle('right');
            });

            $sbRight.on('click', '.closeSidebar', function(e) {
                e.preventDefault();
                $slideBars.slidebars.close();
            });

            $sbRight.on('openCart', function() {
                $slideBars.slidebars.open('right');
            });
        }

        //Initialize fastclick to avoid the click delay on touchscreens.
        if($window.width() <= 1024) {
            FastClick.attach(document.body);
            $('.extended_filters_box .title').on('click', function() {
                $(this).toggleClass('active');
                $(this).next().slideToggle(300);
            });
            $(".filters").detach().appendTo('.filterResponsive');
            $('.filterResponsive').addClass('show');
        }

        $('.toggleMenu', $treeMenu.parent()).on('click', function() {
            $(this).parent().toggleClass('open')
        });
    }
});
